.rankingList2 {
  background: url(../../assets/imgs/rank/rankinglist_bg.png) no-repeat center top;
  background-size: 100% auto;
}
.rankingList2 .rankingList2Box .userName {
  font-size: 0.29rem;
  font-weight: 500;
  color: #000000;
  padding-bottom: 0.1rem;
  position: relative;
  height: 0.52rem;
}
.rankingList2 .rankingList2Box .userName::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
}
.rankingList2 .rankingList2Box .rankItem {
  margin-top: 0.1rem;
  padding: 0 0.05rem;
  font-size: 0.25rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  overflow: hidden;
}
.rankingList2 .rankingList2Box .rankItem span {
  float: right;
}
.rankingList2 .rankingList2Box .order img {
  height: 0.37rem;
  margin-right: 0.1rem;
  vertical-align: -0.1rem;
}
.rankingList2 .rankingList2Box .amount span {
  font-size: 0.27rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #F13333;
}
.rankTop {
  padding-top: 2.3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0.3rem 0.3rem;
}
.rankTop .item {
  color: #FFFFFF;
  font-size: 0.28rem;
  text-align: center;
}
.rankTop .item .icon {
  position: relative;
  width: 1.5rem;
}
.rankTop .item .icon img {
  display: block;
}
.rankTop .item .icon .mask {
  position: relative;
  z-index: 2;
  width: 1.5rem;
}
.rankTop .item .icon .avatar {
  z-index: 1;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  position: absolute;
  left: 0.14rem;
  top: 0.4rem;
}
.rankTop .item.first .icon {
  width: 2rem;
}
.rankTop .item.first .mask {
  width: 2rem;
}
.rankTop .item.first .avatar {
  width: 1.4rem;
  height: 1.4rem;
  left: 0.35rem;
  top: 0.48rem;
}
.layoutBox {
  padding: 0.25rem 0.3rem;
  border-radius: 0.48rem 0.48rem 0 0;
}
.rankInfo {
  color: #373737;
  font-size: 0.28rem;
  line-height: 0.4rem;
  padding: 0.25rem 0.7rem;
  background: #FFFFFF;
  box-shadow: 0px 2px 18px 0px rgba(202, 202, 202, 0.3);
  border-radius: 0.12rem;
  margin-bottom: 0.3rem;
}
.rankInfo h3 {
  font-size: 0.28rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}
.rankInfo .cont {
  display: flex;
  justify-content: space-between;
}
.rankInfo .cont .item {
  min-width: 2rem;
}
.rankingList {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}
.rankingList .num {
  color: #D7D7DB;
  font-size: 0.4rem;
  font-family: Bahnschrift;
  margin-right: 0.25rem;
}
.rankingList .num span {
  font-weight: bold;
  font-style: italic;
}
.rankingList .avatar {
  margin-right: 0.125rem;
}
.rankingList .avatar img {
  width: 0.84rem;
  height: 0.84rem;
  border-radius: 50%;
  display: block;
}
.rankingList .info {
  flex-grow: 1;
  border-bottom: 1px solid #EDEDED;
  padding-top: 0.05rem;
  padding-bottom: 0.15rem;
}
.rankingList .username {
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-bottom: 0.1rem;
}
.rankingList .reward {
  font-size: 0.24rem;
  line-height: 0.35rem;
}
.rankingList .reward i {
  width: 1px;
  height: 0.2rem;
  background: #F5F5F5;
  display: inline-block;
  margin: 0 0.1rem;
}
.rankingList .download {
  float: right;
  padding-top: 0.3rem;
}
.rankingList .download img {
  width: 0.4rem;
  height: 0.4rem;
  display: block;
}
.rankingList:last-child .info {
  border-bottom: 0;
}
