


























































































































    .rankingList2 {
		background: url(../../assets/imgs/rank/rankinglist_bg.png) no-repeat center top;
		background-size: 100% auto;
        .rankingList2Box {
            // padding: 0.3rem;
			// padding-top: 2.3rem;
            // border-bottom: 1px solid #f1f1f1;

            .userName {
                font-size: 0.29rem;
                // font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
                padding-bottom: 0.1rem;
				position: relative;
				height: .52rem;
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
				}
            }

            .rankItem {
                margin-top: 0.1rem;
                padding: 0 0.05rem;
                font-size: 0.25rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                overflow: hidden;

                span {
                    float: right;
                }

            }

            .order {
                img {
                    height: 0.37rem;
                    margin-right: 0.1rem;
                    vertical-align: -0.1rem;
                }
            }

            .amount {
                span {
                    font-size: 0.27rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #F13333;
                }
            }
        }
    }

// ------20200410[start]------
.rankTop {
	padding-top: 2.3rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 0 .3rem .3rem;
	.item {
		color: #FFFFFF;
		font-size: .28rem;
		text-align: center;
		.icon {
			position: relative;
			width: 1.5rem;
			img {
				display: block;
			}
			.mask {
				position: relative;
				z-index: 2;
				width: 1.5rem;
			}
			.avatar {
				z-index: 1;
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 50%;
				position: absolute;
				left: .14rem;
				top: .4rem;
			}
		}
		&.first {
			.icon {
				width: 2rem;
			}
			.mask {
				width: 2rem;
			}
			.avatar {
				width: 1.4rem;
				height: 1.4rem;
				left: .35rem;
				top: .48rem;
			}
		}
	}
}

.layoutBox {
	padding: .25rem .3rem;
	border-radius: .48rem .48rem 0 0;
}

.rankInfo {
	color: #373737;
	font-size: .28rem;
	line-height: .4rem;
	padding: .25rem .7rem;
	background: #FFFFFF;
	box-shadow: 0px 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: .12rem;
	margin-bottom: .3rem;
	h3 {
		font-size: .28rem;
		font-weight: bold;
		margin-bottom: .2rem;
	}
	.cont {
		display: flex;
		justify-content: space-between;
		.item {
			min-width: 2rem;
		}
	}
}

.rankingList {
	display: flex;
	align-items: center;
	margin-bottom: .2rem;
	.num {
		color: #D7D7DB;
		font-size: .4rem;
		font-family: Bahnschrift;
		margin-right: .25rem;
		span {
			font-weight: bold;
			font-style: italic;
		}
	}
	.avatar {
		margin-right: .125rem;
		img {
			width: .84rem;
			height: .84rem;
			border-radius: 50%;
			display: block;
		}
	}
	.info {
		flex-grow: 1;
		border-bottom: 1px solid #EDEDED;
		padding-top: .05rem;
		padding-bottom: .15rem;
	}
	.username {
		font-size: .28rem;
		line-height: .4rem;
		margin-bottom: .1rem;
	}
	.reward {
		font-size: .24rem;
		line-height: .35rem;
		i {
			width: 1px;
			height: .2rem;
			background: #F5F5F5;
			display: inline-block;
			margin: 0 .1rem;
		}
	}
	.download {
		float: right;
		padding-top: .3rem;
		img {
			width: .4rem;
			height: .4rem;
			display: block;
		}
	}
	&:last-child {
		.info {
			border-bottom: 0;
		}
	}
}

// ------20200410[start]------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;